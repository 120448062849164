import React from "react";
import { Steps, Card, Modal } from "antd";
import { LoadingOutlined, UserOutlined, CreditCardOutlined, SettingOutlined } from "@ant-design/icons";
import useMediaQuery from "use-media-antd-query";

const { Step } = Steps;

const isNullOrUndefinedOrEmpty = (value) => {
  return value === null || value === undefined || value === "";
};

const isIdentificationReady = (data) => {
  if (!data) return false;

  const identification = data?.identification;
  const customization = data?.customization;

  if (!identification) return false;

  if (isNullOrUndefinedOrEmpty(identification?.name)) {
    Modal.error({ title: "É necessário indicar o nome antes de continuar..." });
    return false;
  }

  if (isNullOrUndefinedOrEmpty(identification?.email)) {
    Modal.error({ title: "É necessário indicar o email antes de continuar..." });
    return false;
  }

  //phone_required
  if (customization?.phone_required === true) {
    if (isNullOrUndefinedOrEmpty(identification?.phone)) {
      Modal.error({ title: "É necessário indicar o telemóvel antes de continuar..." });
      return false;
    }
  }

  //ask_vatid_on_checkout  and vatid_on_checkout_required
  if (customization?.ask_vatid_on_checkout === true && customization?.vatid_on_checkout_required === true) {
    if (isNullOrUndefinedOrEmpty(identification?.vatid)) {
      Modal.error({ title: "É necessário indicar o múmero de contribuinte antes de continuar..." });
      return false;
    }
  }

  return true;
};

const PaymentStatus = ({ statusAction }) => {
  if (statusAction === "paid") {
    return <CreditCardOutlined />;
  }
  return statusAction === "wait-for-payment" ? <LoadingOutlined /> : <CreditCardOutlined />;
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const StepsHeader = ({ step, data, actionHandler }) => {
  const statusAction = data?.status?.action;
  const colSize = useMediaQuery();
  // console.log("colSize", colSize); // "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

  const stepStatus = (step, stepIndex) => {
    if (statusAction === "paid") {
      if (stepIndex === 0) return "finish";
      if (stepIndex === 1) return "finish";
      if (stepIndex === 2) return "finish";
    }
    if (statusAction === "wait-for-payment") {
      if (stepIndex === 0) return "wait";
      if (stepIndex === 1) return "wait";
      if (stepIndex === 2) return "process";
    }

    if (stepIndex === 0 && step === 0) return "process";
    if (stepIndex === 1 && step === 0) return "wait";
    if (stepIndex === 2 && step === 0) return "wait";

    if (stepIndex === 0 && step === 1) return "finish";
    if (stepIndex === 1 && step === 1) return "process";
    if (stepIndex === 2 && step === 1) return "wait";

    if (stepIndex === 0 && step === 2) return "finish";
    if (stepIndex === 1 && step === 2) return "finish";
    if (stepIndex === 2 && step === 2) return "process";
  };

  // const stepName = (step) => {
  //   if (step === 0) return "Identificação";
  //   if (step === 1) return "Opções";
  //   if (step === 2) return "Pagamento";
  // };

  return (
    <div style={{ marginBottom: 10 }}>
      {colSize !== "xs" && (
        <Card className="steps-container" size={colSize === "xs" ? "small" : "default"}>
          <Steps>
            <Step
              className="as-link"
              status={stepStatus(step, 0)}
              title="Identificação"
              icon={<UserOutlined />}
              onClick={() => {
                if (statusAction !== "wait-for-payment" && statusAction !== "paid") {
                  actionHandler("move", 0);
                }
              }}
            />
            <Step
              className="as-link"
              status={stepStatus(step, 1)}
              title="Opções"
              icon={<SettingOutlined />}
              // onClick={() => {
              //   if (
              //     isIdentificationReady(data) === true &&
              //     statusAction !== "wait-for-payment" &&
              //     statusAction !== "paid"
              //   ) {
              //     actionHandler("move", 1);
              //   }
              // }}
            />
            <Step
              className="as-link"
              status={stepStatus(step, 2)}
              title="Pagamento"
              icon={<PaymentStatus statusAction={statusAction} />}
            />
          </Steps>
        </Card>
      )}

      {colSize === "xs" && (
        <div className="steps-container-mobile">
          <Steps type="default">
            <Step
              className="as-link"
              status={stepStatus(step, 0)}
              title="Identificação"
              icon={<UserOutlined />}
              onClick={() => {
                if (statusAction !== "wait-for-payment" && statusAction !== "paid") {
                  actionHandler("move", 0);
                }
              }}
            />

            <Step
              className="as-link"
              status={stepStatus(step, 1)}
              title="Opções"
              icon={<SettingOutlined />}
              // onClick={() => {
              //   if (
              //     isIdentificationReady(data) === true &&
              //     statusAction !== "wait-for-payment" &&
              //     statusAction !== "paid"
              //   ) {
              //     actionHandler("move", 1);
              //   }
              // }}
            />
            <Step
              className="as-link"
              status={stepStatus(step, 2)}
              title="Pagamento"
              icon={<PaymentStatus statusAction={statusAction} />}
            />
          </Steps>
        </div>
      )}
    </div>
  );
};

export default StepsHeader;
