import React, { useEffect, useState } from "react";

import { Form, Input, Button, Row, Col, Alert, Modal } from "antd";
import { RightOutlined } from "@ant-design/icons";
import SelectGeoLocationOptions from "./CountrySelector";
import useMediaQuery from "use-media-antd-query";
import Coupon from "../../Shared/Coupon";
import { saveOnLocalStorage } from "../../../utils/helper";
import { BuyerInvoiceInfo, IsNullUndefinedEmpty } from "utils/helper";
import emailSpellChecker from "@zootools/email-spell-checker";
import { POPULAR_DOMAINS, POPULAR_TLDS } from "utils/emails";
import { vatid_get } from "../../../api/validations";
import { useInView } from "react-intersection-observer";

import { PhoneInput, defaultCountries, parseCountry } from "react-international-phone";
import phoneCountriesAllowed from "../../../config/phone_countries.json";
import { validatePhoneNumber } from "../../../utils/validations";
import "react-international-phone/style.css";

const Identification = ({ data, actionHandler }) => {
  const [form] = Form.useForm();

  const { country, region } = data?.geo;
  const { countries } = data;
  const colSize = useMediaQuery();
  const isWidget = window?.location?.hash?.substr(1) === "widget";

  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);
  const [validationTrigger, setValidationTrigger] = useState("onBlur");
  const [inputEmail, setInputEmail] = React.useState("");
  const [errorLoadingVatIdData, setErrorLoadingVatIdData] = useState(false);
  const [emailSpellSuggestion, setEmailSpellSuggestion] = useState(null);
  const [phoneNumberData, setPhoneNumberData] = useState(null);
  const [loadingNextButton, setLoadingNextButton] = useState(false);
  const customFieldSize = data?.customization?.fields_size || "normal";
  const isSmallCustomFieldSize = customFieldSize === "small";

  let updateFieldsTimeout = null;
  const { ref: nextButtonRef, entry: isNextButtonVisible } = useInView({
    /* Optional options */
    threshold: 1,
    //rootMargin: "20px",
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(data.identification);
    setLoadingNextButton(true);
    setInputEmail(data.identification?.email);

    if (IsNullUndefinedEmpty(data.identification?.phone) === false) {
      setPhoneNumberData({ phone: data.identification?.phone });
    }

    if (
      //data.ask_vatid_on_checkout === true &&
      data.trigger_coupon === true &&
      data.temp_coupon !== undefined &&
      data.coupon.coupon_code === undefined
    ) {
      triggerCoupon();
    }
  }, []);

  useEffect(() => {
    emailSpellCheckerInfo(inputEmail);
    setLoadingNextButton(false);
  }, [inputEmail]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const allowedcCountriesList = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return phoneCountriesAllowed.includes(iso2);
  });

  const triggerCoupon = () => {
    setTimeout(() => {
      actionHandler("coupon", data.temp_coupon);
    }, 500);
  };

  const validateVatId = async (vatid) => {
    return new Promise((resolve) => {
      vatid_get(vatid)
        .then((res) => {
          resolve({ status: true, data: res.data });
          setErrorLoadingVatIdData(false);
        })
        .catch((err) => {
          resolve({ status: false, data: err });
          setErrorLoadingVatIdData(true);
        });
    });
  };

  const onUpdateFields = () => {
    try {
      clearTimeout(updateFieldsTimeout);

      updateFieldsTimeout = setTimeout(() => {
        const data = form.getFieldsValue();
        data.email = inputEmail;
        data.phone = phoneNumberData?.phone;

        if (IsNullUndefinedEmpty(data)) return;
        actionHandler("update-indentification", data);
      }, 1000);

      // Error handling
    } catch (error) {
      // console.log(error);
    }
  };

  const awaitUntilValuesAreSet = (values) => {
    return new Promise(async (resolve) => {
      let attemptsToSetEmail = 0;
      let attemptsToSetPhone = 0;
      if (values.email !== inputEmail) {
        setInputEmail(values.email);
        setLoadingNextButton(true);
      }

      do {
        await delay(100);
        attemptsToSetEmail++;
      } while (values.email !== inputEmail && attemptsToSetEmail < 25);

      if (values.email !== inputEmail) {
        Modal.error({
          title: "Erro",
          content: "Ocorreu um erro ao tentar atualizar o email. Por favor, tenta novamente.",
          okText: "OK",
        });
        resolve(false);
        return;
      }

      if (values.phone !== phoneNumberData?.phone) {
        setPhoneNumberData({ phone: values.phone });
        setLoadingNextButton(true);
      }

      do {
        await delay(100);
        attemptsToSetPhone++;
      } while (values.phone !== phoneNumberData?.phone && attemptsToSetPhone < 25);

      if (values.phone !== phoneNumberData?.phone) {
        Modal.error({
          title: "Erro",
          content: "Ocorreu um erro ao tentar atualizar o número de telemóvel. Por favor, tenta novamente.",
          okText: "OK",
        });
        resolve(false);
        return;
      }

      resolve(true);
    });
  };
  const onFinish = async (values) => {
    try {
      console.log("onFinish", values);
      const valuesSetResponse = await awaitUntilValuesAreSet(values);
      if (valuesSetResponse === false) return;

      actionHandler("update-indentification", values);

      if (errorLoadingVatIdData && !IsNullUndefinedEmpty(values?.vatid)) {
        Alert.error("Número de contribuinte inválido!");
        return;
      }

      if (values?.email !== undefined && values?.email !== null && values?.email !== "") {
        values.email = values.email.toLowerCase();
        values.email = values.email.replace(/\s/g, "");
      }

      if (values.phone === undefined || values.phone === null) {
        values.phone = "";
      }
      values.country = country.value;
      values.region = region.value;
      //values.tax_percent = selectedRegion.tax_percent;

      // Validate phone number and get validation object (isValid, title, message)
      const phoneValidation = validatePhoneNumber(
        phoneNumberData.phone,
        phoneNumberData,
        setPhoneNumberData,
        data?.customization?.phone_required === true
      );

      if (phoneValidation.isValid === false && phoneValidation.requiredWarning !== true) {
        Modal.warning({
          title: phoneValidation.title,
          content: phoneValidation.message,
          okText: "OK",
        });
        return;
      }

      if (phoneValidation.isValid === false && phoneValidation.requiredWarning === true) {
        form.setFieldsValue({ phone: phoneValidation.local_phone });
        form.validateFields(["phone"]);
        return;
      }

      actionHandler("identification", values);

      // Error handling
    } catch (error) {
      console.log("onFinish Error", error);
    }
  };

  const updateCountryAndRegion = (country_code, region_code) => {
    actionHandler("geo", { country_code, region_code });
  };

  const onFinishFailed = (errorInfo) => {
    setValidationTrigger("onChange");

    console.log("Failed:", errorInfo);
  };

  // const countriesMenu = (
  //   <Menu
  //     items={getCountriesList()}
  //     onClick={(e) => setSelectedCountry(countries.find((el) => el.value === e.key))}
  //   />
  // );
  // const regionsMenu = (
  //   <Menu
  //     items={getRegionsList()}
  //     onClick={(e) => setSelectedRegion(selectedCountry.regions.find((el) => el.value === e.key))}
  //   />
  // );

  // const getSelectedCountryName = (selCountry) => {
  //   try {
  //     console.log("selCountry", selCountry);
  //     if (selCountry === undefined || selCountry === null) return "Portugal";
  //     const selectedCountry = countries.find((country) => country.value === selCountry);
  //     return selectedCountry.localName;
  //   } catch (error) {
  //     // setSelectedCountry("pt");
  //   }
  // };

  // const getSelectedRegionName = (selRegion) => {
  //   if (!country) return "pt";
  //   try {
  //     const selectedRegion = countries.regions.find((region) => region.value === selRegion);
  //     return selectedRegion.name;
  //   } catch (error) {
  //     setSelectedRegion("pt");
  //   }
  // };

  const onChangeEmail = (event, { newValue }) => {
    setInputEmail(newValue.replace(/\s/g, ""));
  };

  const emailSpellCheckerInfo = (email) => {
    if (!email) return;
    try {
      const suggestedEmail = emailSpellChecker.run({
        domains: POPULAR_DOMAINS,
        topLevelDomains: POPULAR_TLDS,
        email,
      });

      if (suggestedEmail) {
        setEmailSpellSuggestion(suggestedEmail.full.trim().toLowerCase());
      } else {
        setEmailSpellSuggestion(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`identification-container-${customFieldSize}`}>
      {/* <Title level={4} className="step-title">
        Identificação
      </Title> */}

      <Form
        form={form}
        scrollToFirstError={{
          behavior: "smooth",
          block: "start",
          inline: "start",
          scrollMode: "always",
        }}
        name="frm-identification"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onBlurCapture={(e) => {
          try {
            saveOnLocalStorage("identification", form.getFieldsValue());
          } catch (error) {
            console.log(error);
          }
        }}
        autoComplete="off"
      >
        <Row gutter={isSmallCustomFieldSize ? 10 : 0}>
          <Col xs={24} sm={isSmallCustomFieldSize ? 12 : 24}>
            <Form.Item
              label="Nome"
              name="name"
              hasFeedback
              rules={[
                { required: true, message: "Este campo é de preenchimento obrigatório!" },
                {
                  validator: (rule, value) => {
                    if (!value) return Promise.resolve();
                    if (value.length === 0) Promise.resolve();

                    if (value?.length < 3) {
                      return Promise.reject("Nome demasiado curto!");
                    }

                    if (value?.length > 80) {
                      return Promise.reject("Nome demasiado longo!");
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              validateTrigger={validationTrigger}
            >
              {/* <Input size="large" placeholder="O teu nome (no minimo primeiro e último nome)" /> */}
              <Input size="large" placeholder="O teu nome..." onChange={(e) => onUpdateFields(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={isSmallCustomFieldSize ? 12 : 24}>
            <Form.Item
              className={emailSpellSuggestion ? "mb-5" : null}
              label="Endereço de e-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Este campo é de preenchimento obrigatório!",
                },
                { type: "email", message: "Este campo deve ser um e-mail válido!" },
              ]}
              validateTrigger={validationTrigger}
              hasFeedback
            >
              <Input
                size="large"
                placeholder="O teu endereço de e-mail..."
                value={inputEmail}
                on
                onChange={(e) => {
                  let _email = e.target.value.trim();

                  // Remove any kind of accents
                  _email = _email.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                  setLoadingNextButton(true);
                  setInputEmail(_email);
                  onUpdateFields();
                }}
              />
            </Form.Item>
            {emailSpellSuggestion && (
              <div style={{ marginTop: 6 }}>
                <Alert
                  message={
                    <>
                      Será que querias dizer{" "}
                      <Button
                        type="link"
                        className="m-0 p-0"
                        onClick={() => {
                          form.setFieldsValue({ email: emailSpellSuggestion });
                          setLoadingNextButton(true);
                          setInputEmail(emailSpellSuggestion);
                          //setEmailSpellSuggestion(null);
                        }}
                      >
                        {emailSpellSuggestion}
                      </Button>
                      ?
                    </>
                  }
                  type="error"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={isSmallCustomFieldSize ? 10 : 0}>
          <Col xs={24} sm={isSmallCustomFieldSize ? 12 : 24}>
            <Form.Item noStyle>
              <Form.Item
                className={phoneNumberData?.error === true ? "mb-0" : "mb-1"}
                label="Número de telemóvel"
                name="phone"
                rules={[
                  {
                    required: data?.customization?.phone_required === true,
                    message: "Este campo é de preenchimento obrigatório!",
                  },
                ]}
                hasFeedback
              >
                <PhoneInput
                  className="internacional-phone-input"
                  value={phoneNumberData?.phone}
                  defaultCountry="pt"
                  placeholder="Número de Telemóvel"
                  countries={allowedcCountriesList}
                  preferredCountries={["pt", "es", "fr", "ie", "gb"]}
                  disableDialCodeAndPrefix={true}
                  onChange={(phone, meta) => {
                    setPhoneNumberData({ phone, data: meta, error: false });
                    onUpdateFields();
                    setLoadingNextButton(true);
                    setTimeout(() => {
                      setLoadingNextButton(true);
                    }, 1000);
                  }}
                />
              </Form.Item>
              {phoneNumberData?.error === true && (
                <Alert
                  message="O número de telemóvel indicado não é válido."
                  type="error"
                  showIcon
                  style={{ marginTop: 10, marginBottom: 15 }}
                />
              )}
            </Form.Item>
          </Col>

          {/* Ask VAT ID during indentification */}
          {data.ask_vatid_on_checkout === true && (
            <Col xs={24} sm={isSmallCustomFieldSize ? 12 : 24}>
              <Form.Item
                label="Número de contribuinte"
                name="vatid"
                rules={[
                  {
                    required: data?.customization?.vatid_on_checkout_required === true,
                    message: "O número de contribuinte é de preenchimento obrigatório!",
                  },
                  {
                    validator: async (_, value) => {
                      // If Country isnt Portugal, then dont validate VATID

                      if (country?.value?.toUpperCase() !== "PT") return Promise.resolve();

                      if (value === undefined || value === "" || value === null) {
                        return Promise.resolve();
                      }

                      if (value.length > 0 && value.length < 9 && isNaN(value) === false) {
                        return Promise.reject("Número de contribuinte incompleto.");
                      }
                      if (value.length > 0 && value.length < 9 && isNaN(value) === true) {
                        return Promise.reject("Número de contribuinte inválido.");
                      }

                      const rv = await validateVatId(value, true);
                      if (rv.status) {
                        if (rv.data.status === false) {
                          return Promise.reject(rv.data.message);
                        } else {
                          return Promise.resolve("Número de contribuinte válido!");
                        }
                      } else {
                        return Promise.reject("Não foi possível verificar o número de contribuinte.");
                      }
                    },
                  },
                  {
                    required: false,
                  },
                ]}
                hasFeedback
                validateTrigger={validationTrigger}
                // rules={[
                //   {
                //     required: data?.customization?.vatid_on_checkout_required === true,
                //     message: "Este campo é de preenchimento obrigatório!",
                //   },
                // ]}
              >
                <Input
                  size="large"
                  onChange={(phone) => onUpdateFields()}
                  //placeholder="O teu número de contribuinte (Opcional)"
                  placeholder={`Número de contribuinte ${
                    data?.customization?.vatid_on_checkout_required !== true ? "(Opcional)" : ""
                  }`}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {data?.allow_coupons?.active === true && data?.allow_coupons?.show_on_identification === true && (
          <Form.Item label="Cupão de desconto">
            <Coupon show data={data} actionHandler={actionHandler} />
          </Form.Item>
        )}
        {/* <Form.Item
          validateStatus={termsAccepted === true ? "success" : "error"}
          help={
            termsAccepted === false ? (
              <span className="ant-form-item-explain-error">
                É necessário aceitar os termos e condições antes de continuar...
              </span>
            ) : (
              ""
            )
          }
        >
          <Checkbox onChange={(e) => setTermsAccepted(e.target.checked)}>Aceito os termos e condições</Checkbox>
        </Form.Item> */}
        {isMobileSize && (
          <>
            <Form.Item name="country-region">
              <div style={{ display: "inline-flex", alignItems: "center", width: "100%" }}>
                <label style={{ marginRight: 5 }}>País:</label>
                <SelectGeoLocationOptions
                  countries={countries}
                  selectedCountry={country}
                  selectedRegion={region}
                  onChange={(country, region) => updateCountryAndRegion(country, region)}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                ref={nextButtonRef}
                block
                //disabled={loadingNextButton}
                size="large"
                type="primary"
                htmlType="submit"
                className="submit-button"
              >
                Ir para o próximo passo <RightOutlined />
              </Button>
            </Form.Item>
          </>
        )}
        {!isMobileSize && (
          <div style={{ marginTop: 15, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Form.Item name="country-region">
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <label style={{ marginRight: 5 }}>País:</label>
                <SelectGeoLocationOptions
                  countries={countries}
                  selectedCountry={country}
                  selectedRegion={region}
                  onChange={(country, region) => updateCountryAndRegion(country, region)}
                />
              </div>
            </Form.Item>

            <Form.Item>
              <Button ref={nextButtonRef} size="large" type="primary" htmlType="submit" className="submit-button">
                Ir para o próximo passo <RightOutlined />
              </Button>
            </Form.Item>
          </div>
        )}
        {isNextButtonVisible?.isVisible === false && (
          <div className="nextButtonFixed">
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="submit-button-smaller">
                Ir para o próximo passo <RightOutlined />
              </Button>
            </Form.Item>
          </div>
        )}
      </Form>
      <div className="billing-data-notice">
        <BuyerInvoiceInfo isWidget={isWidget} data={data?.thankyou_page} />
      </div>
    </div>
  );
};

export default Identification;
